/*---------- Normalize ----------*/
@font-face {
	  font-family: 'Della Respira';
	  font-style: normal;
	  font-weight: 400;
	  src:	local('Della Respira'), local('DellaRespira-Regular'),
			url(https://fonts.googleapis.com/css?family=Della+Respira);
			unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
	                  U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
	                  U+2215, U+FEFF, U+FFFD;
	    }
@import url('https://fonts.googleapis.com/css?family=Uncial+Antiqua');

*	{
  border: none;
  margin: 0;
  padding: 0;
	font-family: $primary-font;
  text-decoration: none;
  text-align:	center;
  text-transform: none;
  font-weight: normal;
  display: block;
  vertical-align: baseline;
  background: transparent;
  color:  inherit;
  font: inherit;
  box-sizing: border-box;
  }
b, strong {
    font-weight: bold;
  }
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  }
sup {
  top: -.5em;
  }
sub {
  bottom: -.25em;
  }
title {
  display: none
  }

