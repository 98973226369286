$lg-break-point: 1200px;
$break-point: 960px;
$md-break-point: 640px;
$sm-break-point: 320px;

$aside-width: 35%;
$content-wrapper-width: 62%;
$article-max-width: 740px;
$post-margin-left: 38%;

$img:  "../img";
$js:   "../js";
$font: "../font";

$bodyText: SlateGray;

$primary: indigo;
$primary-font: Geneva, Arial, san-serif;
$primary-background: Snow;

$accent: Crimson;
$accent-font: 'Della Respira', "Times New Roman", Times, serif;
$accent-background: FloralWhite;

$reference: Maroon;
$reference-hover: Tomato;
$reference-visited: Brown;
$reference-font: 'Della Respira', "Times New Roman", Times, serif;
$reference-background: SeaShell;

$caption: White;
$caption-font: 'Della Respira', "Times New Roman", Times, serif;
$caption-background: Black;

// $test-font: "DaleLands Uncial"