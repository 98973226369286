/* Code Highlight
------------------- */
.hll {
    background-color: #ffc;
}
.c {
    color: #93a1a1;
    font-style: italic;
} /* Comment */
/* .err { color: #dc322f }Error */
.g {
    color: #657b83;
} /* Generic */
.k {
    color: #859900;
} /* Keyword */
.l {
    color: #657b83;
} /* Literal */
.n {
    color: #586e75;
} /* Name */
.o {
    color: #657b83;
} /* Operator */
.x {
    color: #657b83;
} /* Other */
.p {
    color: #657b83;
} /* Punctuation */
.cm {
    color: #93a1a1;
    font-style: italic;
} /* Comment.Multiline */
.cp {
    color: #93a1a1;
    font-style: italic;
} /* Comment.Preproc */
.c1 {
    color: #93a1a1;
    font-style: italic;
} /* Comment.Single */
.cs {
    color: #93a1a1;
    font-style: italic;
} /* Comment.Special */
.gd {
    color: #657b83;
} /* Generic.Deleted */
.ge {
    color: #657b83;
} /* Generic.Emph */
.gr {
    color: #657b83;
} /* Generic.Error */
.gh {
    color: #657b83;
} /* Generic.Heading */
.gi {
    color: #657b83;
} /* Generic.Inserted */
.go {
    color: #657b83;
} /* Generic.Output */
.gp {
    color: #c65d09;
} /* Generic.Prompt */
.gs {
    color: #657b83;
} /* Generic.Strong */
.gu {
    color: #657b83;
} /* Generic.Subheading */
.gt {
    color: #657b83;
} /* Generic.Traceback */
.kc {
    color: #859900;
} /* Keyword.Constant */
.kd {
    color: #859900;
} /* Keyword.Declaration */
.kn {
    color: #cb4b16;
} /* Keyword.Namespace */
.kp {
    color: #cb4b16;
} /* Keyword.Pseudo */
.kr {
    color: #859900;
} /* Keyword.Reserved */
.kt {
    color: #859900;
} /* Keyword.Type */
.ld {
    color: #657b83;
} /* Literal.Date */
.m {
    color: #2aa198;
} /* Literal.Number */
.s {
    color: #2aa198;
} /* Literal.String */
.na {
    color: #657b83;
} /* Name.Attribute */
.nb {
    color: #268bd2;
} /* Name.Builtin */
.nc {
    color: #268bd2;
} /* Name.Class */
.no {
    color: #b58900;
} /* Name.Constant */
.nd {
    color: #cb4b16;
} /* Name.Decorator */
.ni {
    color: #cb4b16;
} /* Name.Entity */
.ne {
    color: #cb4b16;
} /* Name.Exception */
.nf {
    color: #268bd2;
} /* Name.Function */
.nl {
    color: #657b83;
} /* Name.Label */
.nn {
    color: #b58900;
} /* Name.Namespace */
.nx {
    color: #657b83;
} /* Name.Other */
.py {
    color: #268bd2;
} /* Name.Property */
.nt {
    color: #859900;
} /* Name.Tag */
.nv {
    color: #cd4b16;
} /* Name.Variable */
.ow {
    color: #859900;
} /* Operator.Word */
.w {
    color: #fdf6e3;
} /* Text.Whitespace */
.mf {
    color: #2aa198;
} /* Literal.Number.Float */
.mh {
    color: #2aa198;
} /* Literal.Number.Hex */
.mi {
    color: #2aa198;
} /* Literal.Number.Integer */
.mo {
    color: #2aa198;
} /* Literal.Number.Oct */
.sb {
    color: #2aa198;
} /* Literal.String.Backtick */
.sc {
    color: #2aa198;
} /* Literal.String.Char */
.sd {
    color: #2aa198;
} /* Literal.String.Doc */
.s2 {
    color: #2aa198;
} /* Literal.String.Double */
.se {
    color: #cb4b16;
} /* Literal.String.Escape */
.sh {
    color: #2aa198;
} /* Literal.String.Heredoc */
.si {
    color: #cb4b16;
} /* Literal.String.Interpol */
.sx {
    color: #2aa198;
} /* Literal.String.Other */
.sr {
    color: #2aa198;
} /* Literal.String.Regex */
.s1 {
    color: #2aa198;
} /* Literal.String.Single */
.ss {
    color: #2aa198;
} /* Literal.String.Symbol */
.bp {
    color: #268bd2;
    font-weight: bold;
} /* Name.Builtin.Pseudo */
.vc {
    color: #268bd2;
} /* Name.Variable.Class */
.vg {
    color: #268bd2;
} /* Name.Variable.Global */
.vi {
    color: #268bd2;
} /* Name.Variable.Instance */
.il {
    color: #2aa198;
} /* Literal.Number.Integer.Long */
